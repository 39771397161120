import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import PreviousPage from './previous';

import isTouchDevice from '../../../utils/is-touch-device';
import useOnMount from '../../../utils/use-on-mount';
import useTouch from '../../../utils/use-touch';

const PreviousPageContainer = ({
  hidden,
  to,
}) => {
  const ref = useRef();

  const clickLink = () => {
    const a = ref.current.querySelector('a');
    a.click();
  };

  const touchHandlers = useTouch(clickLink);

  useOnMount(() => {
    const isTouch = isTouchDevice();
    if (isTouch) {
      document.addEventListener('touchstart', touchHandlers.handleTouchStart);
      document.addEventListener('touchmove', touchHandlers.handleSwipeRight);
      document.addEventListener('touchend', touchHandlers.handleTouchEnd);
    }

    return () => {
      if (isTouch) {
        document.removeEventListener('touchstart', touchHandlers.handleTouchStart);
        document.removeEventListener('touchmove', touchHandlers.handleSwipeRight);
        document.removeEventListener('touchend', touchHandlers.handleTouchEnd);
      }
    };
  });

  return (
    <PreviousPage
      hidden={hidden}
      ref={ref}
      to={to}
    />
  );
};

PreviousPageContainer.defaultProps = {
  hidden: false,
};

PreviousPageContainer.propTypes = {
  hidden: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

export default PreviousPageContainer;
