import { useEffect } from 'react';
import isTouchDevice from './is-touch-device';

const isOverFlowing = (element) => element.clientHeight < element.scrollHeight;

const shouldAddClass = (showOnlyOnTouch) => {
  if (
    !showOnlyOnTouch
    || (showOnlyOnTouch && isTouchDevice())
  ) {
    return true;
  }
  return false;
};

const defaultOptions = {
  showOnlyOnTouch: true,
};

const useVisibleScrollBar = (ref, className, options) => {
  useEffect(() => {
    const element = ref.current;
    const { showOnlyOnTouch } = { ...defaultOptions, ...options };
    if (element) {
      if (isOverFlowing(element) && shouldAddClass(showOnlyOnTouch)) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }
  });
};

export default useVisibleScrollBar;
