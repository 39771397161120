import { useRef } from 'react';

const useTouch = (func) => {
  const xDown = useRef();
  const yDown = useRef();

  const getTouches = (e) => e.touches;

  const handleTouchStart = (e) => {
    const firstTouch = getTouches(e)[0];
    xDown.current = firstTouch.clientX;
    yDown.current = firstTouch.clientY;
  };

  const handleTouchEnd = () => {
    xDown.current = null;
    yDown.current = null;
  };

  const handleSwipeLeft = (e) => {
    if (!xDown.current || !yDown.current) {
      return;
    }

    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = xDown.current - xUp;
    const yDiff = yDown.current - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 100) {
        xDown.current = null;
        yDown.current = null;
        func();
      }
    }
  };

  const handleSwipeRight = (e) => {
    if (!xDown.current || !yDown.current) {
      return;
    }

    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = xDown.current - xUp;
    const yDiff = yDown.current - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff < -100) {
        xDown.current = null;
        yDown.current = null;
        func();
      }
    }
  };

  return {
    handleSwipeLeft,
    handleSwipeRight,
    handleTouchEnd,
    handleTouchStart,
  };
};

export default useTouch;
