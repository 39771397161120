import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-12.css';

const PageTwelve = forwardRef((props, ref) => (
  <>
    <SEO title="Page 12" />
    <main className="page12">
      <div className="page__image-container page12__image-portrait-container">
        <StaticImage
          alt="Sockpickers in bunk beds, sockpickers hanging out and even a grumpy sockpicker"
          aspectRatio={3894 / 4407}
          className="page12__image-portrait page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          objectFit="contain"
          placeholder="tracedSVG"
          src="../../images/page-12-portrait.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text page12__left">
          <div>
            <p>
              They became very quiet when they saw Amanda, but when Jimbugs said she was his new friend they soon
              forgot about their visitor and continued what they were doing.
            </p>
            <p>
              Most of them were busy making things from socks, which were hung around the room.
            </p>
            <p>
              Others were resting in wooden bunk beds piled from the ground right up
              to the ceiling.
            </p>
          </div>
          <div className="page__image-container page12__image-landscape1-container">
            <StaticImage
              alt="A sockpicker hanging out and another one being a grump"
              aspectRatio={2909 / 2446}
              className="page__image-border"
              height={300}
              layout="constrained"
              loading="eager"
              objectFit="contain"
              placeholder="tracedSVG"
              src="../../images/page-12-landscape1.png"
            />
          </div>
        </div>
      </div>
      <div className="page__image-container page12__image-landscape2-container">
        <div className="page__image-border-container">
          <StaticImage
            alt="Sockpickers stacked to the ceiling in bunk beds"
            aspectRatio={2500 / 5115}
            className="page12__image-landscape2"
            height={750}
            layout="constrained"
            loading="eager"
            objectFit="contain"
            placeholder="tracedSVG"
            src="../../images/page-12-landscape2.png"
          />
        </div>
      </div>
      <PreviousPage to="/page-11" />
      <NextPage to="/page-13" />
    </main>
  </>
));

const PageTwelveContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageTwelve ref={textRef} />;
};

export default PageTwelveContainer;
