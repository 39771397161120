import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import Link from '../../link/link';

import './next.css';

const NextPage = forwardRef((
  {
    hidden,
    to,
  },
  ref,
) => (
  <div
    className="next"
    ref={ref}
    style={{
      visibility: hidden ? 'hidden' : 'visible',
    }}
  >
    <Link
      color="primary"
      direction="left"
      swipe
      to={to}
    >
      Next
    </Link>
  </div>
));

NextPage.propTypes = {
  hidden: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
};

export default NextPage;
